import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { App } from 'antd';
import { EmployerThemeComponent } from '../components';

export default function EmployerTheme() {
  const selectedEmployer = useSelector((state) => state.shared.selectedEmployer);
  const { data: theme, loading } = useSelector((state) => state.employerTheme.employerTheme);
  const { message } = App.useApp();

  useEffect(() => {
    if (!selectedEmployer?.value) {
      message.error('Please select an employer.');
    }
  }, [selectedEmployer?.value]);

  return (
    <EmployerThemeComponent selectedEmployer={selectedEmployer} theme={theme} loading={loading} />
  );
}
