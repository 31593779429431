import React, { useMemo, useState } from 'react';
import { Button, Card, Col, message, Row, Space, Typography } from 'antd';
import ImageInput from './ImageInput';
import { updateEmployerThemeLogo } from '../../actions';
import { useDispatch } from 'react-redux';

const { Text } = Typography;

const Extra = (props) => (
  <Space align="center" size="large">
    <Text type="secondary">Supported formats: JPG, PNG, SVG</Text>
    {/*<Button icon={<ReloadOutlined />} danger={true} onClick={() => {}}>
      Reset Logo
    </Button>*/}
  </Space>
);

const ValidationConfig = {
  logoFullVersion: {
    validTypes: ['image/png', 'image/jpeg', 'image/svg+xml'],
    maxSize: 300 * 1024, // #00KB in bytes
  },
  logoIconVersion: {
    validTypes: ['image/png', 'image/jpeg', 'image/svg+xml'],
    maxSize: 200 * 1024, // 200KB in bytes
  },
};

export default function UploadLogoSection({ theme, selectedEmployer }) {
  const dispatch = useDispatch();
  const [files, setFiles] = useState({ logoFullVersion: null, logoIconVersion: null });

  const validateFile = (fileKey, file) => {
    if (!file) return;
    const { validTypes, maxSize } = ValidationConfig[fileKey];
    if (!validTypes?.includes(file.type)) {
      throw new Error('Invalid file type. Please upload PNG, JPEG, or SVG files only.');
    }

    if (maxSize && file.size > maxSize) {
      throw new Error(`File size exceeds the maximum limit of ${maxSize / 1024}KB.`);
    }
  };
  const onFileChange = (fileKey) => (file) => {
    try {
      validateFile(fileKey, file);
      setFiles((prev) => ({ ...prev, [fileKey]: file }));
      return true;
    } catch (error) {
      message.error(error.message);
    }
    return false;
  };

  const isSubmitDisabled = useMemo(
    () => !files.logoFullVersion && !files.logoIconVersion,
    [files.logoFullVersion, files.logoIconVersion]
  );

  const onSubmit = () => {
    dispatch(updateEmployerThemeLogo({ ...files, employerId: selectedEmployer?.value }));
    setFiles({ logoFullVersion: null, logoIconVersion: null });
  };

  return (
    <Card title="Theme Logo" extra={<Extra />}>
      <Row gutter={[16, 16]}>
        <ImageInput
          imageFile={files.logoFullVersion}
          setImageFile={onFileChange('logoFullVersion')}
          label="Full Size Logo"
          theme={theme}
          defaultImageURL={theme?.logo?.fullVersionUrl}
          description={<Text type="secondary">Max file size: 300KB</Text>}
          aspectRatio={5} // 225 / 45
        />
        <Col span={2} />
        <ImageInput
          imageFile={files.logoIconVersion}
          setImageFile={onFileChange('logoIconVersion')}
          defaultImageURL={theme?.logo?.iconVersionUrl}
          label="Icon Size Logo"
          description={<Text type="secondary">Max file size: 200KB</Text>}
          aspectRatio={1} // 45/45
          theme={theme}
        />

        <Col span={4} offset={19}>
          <Button block type="primary" size="middle" disabled={isSubmitDisabled} onClick={onSubmit}>
            Save Changes
          </Button>
        </Col>
      </Row>
    </Card>
  );
}
