import { combineReducers } from 'redux';
import {
  GET_EMPLOYER_THEME_ERROR,
  GET_EMPLOYER_THEME_LOADING,
  GET_EMPLOYER_THEME_SUCCESS,
  SET_EMPLOYER_THEME,
  UPDATE_EMPLOYER_THEME_CS_ERROR,
  UPDATE_EMPLOYER_THEME_CS_LOADING,
  UPDATE_EMPLOYER_THEME_CS_SUCCESS,
  UPDATE_EMPLOYER_THEME_LOGO_ERROR,
  UPDATE_EMPLOYER_THEME_LOGO_LOADING,
  UPDATE_EMPLOYER_THEME_LOGO_SUCCESS,
} from './actions';

const initialStateObject = { loading: false, data: {}, error: '' };

const employerTheme = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }

  switch (action.type) {
    // GET_EMPLOYER_THEME
    case GET_EMPLOYER_THEME_LOADING: {
      return { loading: true, data: {}, error: '' };
    }
    case GET_EMPLOYER_THEME_SUCCESS: {
      return { loading: false, data: action.data, error: '' };
    }
    case GET_EMPLOYER_THEME_ERROR: {
      return { loading: false, data: {}, error: action.errorMessage };
    }

    // UPDATE_EMPLOYER_THEME_COLOR_SCHEME
    case UPDATE_EMPLOYER_THEME_CS_LOADING: {
      return { loading: true, data: {}, error: '' };
    }
    case UPDATE_EMPLOYER_THEME_CS_SUCCESS: {
      return { loading: false, data: action.data, error: '' };
    }
    case UPDATE_EMPLOYER_THEME_CS_ERROR: {
      return { loading: false, data: {}, error: action.errorMessage };
    }

    // UPDATE_EMPLOYER_THEME_LOGO
    case UPDATE_EMPLOYER_THEME_LOGO_LOADING: {
      return { loading: true, data: {}, error: '' };
    }
    case UPDATE_EMPLOYER_THEME_LOGO_SUCCESS: {
      return { loading: false, data: action.data, error: '' };
    }
    case UPDATE_EMPLOYER_THEME_LOGO_ERROR: {
      return { loading: false, data: {}, error: action.errorMessage };
    }

    case SET_EMPLOYER_THEME:
      return { loading: false, data: {}, error: '' };

    default:
      return state;
  }
};

export default combineReducers({ employerTheme });
