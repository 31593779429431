import { combineReducers } from 'redux';
import {
  TOGGLE_SIDEBAR,
  SET_SELECTED_EMPLOYER,
  GET_EMPLOYERS_LIST_LOADING,
  GET_EMPLOYERS_LIST_SUCCESS,
  GET_EMPLOYERS_LIST_ERROR,
  GET_PRESIGNED_URL_LOADING,
  GET_PRESIGNED_URL_SUCCESS,
  GET_PRESIGNED_URL_ERROR,
  SET_AUTH_CODE,
  GET_EMPLOYER_CONFIGURATION_LOADING,
  GET_EMPLOYER_CONFIGURATION_SUCCESS,
  GET_EMPLOYER_CONFIGURATION_ERROR,
} from './actions';

const initialStateObject = {
  loading: false,
  data: {},
  error: '',
};

const isSidebarCollapsed = (state, action) => {
  if (typeof state === 'undefined') {
    return false;
  }
  switch (action.type) {
    case TOGGLE_SIDEBAR: {
      return action.data;
    }
    default:
      return state;
  }
};

const authCode = (state, action) => {
  if (typeof state === 'undefined') {
    return '';
  }
  switch (action.type) {
    case SET_AUTH_CODE: {
      return action.data;
    }
    default:
      return state;
  }
};

const selectedEmployer = (state, action) => {
  if (typeof state === 'undefined') {
    return {};
  }
  switch (action.type) {
    case SET_SELECTED_EMPLOYER: {
      return { label: action.data.companyName, value: action.data.id };
    }
    default:
      return state;
  }
};

const employersList = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_EMPLOYERS_LIST_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_EMPLOYERS_LIST_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_EMPLOYERS_LIST_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const presignedUrl = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_PRESIGNED_URL_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_PRESIGNED_URL_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_PRESIGNED_URL_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const employerConfiguration = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_EMPLOYER_CONFIGURATION_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
      };
    }
    case GET_EMPLOYER_CONFIGURATION_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
      };
    }
    case GET_EMPLOYER_CONFIGURATION_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  employerConfiguration,
  isSidebarCollapsed,
  selectedEmployer,
  presignedUrl,
  employersList,
  authCode
});
