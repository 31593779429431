import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_EMPLOYER_THEME,
  GET_EMPLOYER_THEME_ERROR,
  GET_EMPLOYER_THEME_LOADING,
  GET_EMPLOYER_THEME_SUCCESS,
  UPDATE_EMPLOYER_THEME_CS,
  UPDATE_EMPLOYER_THEME_CS_ERROR,
  UPDATE_EMPLOYER_THEME_CS_LOADING,
  UPDATE_EMPLOYER_THEME_CS_SUCCESS,
  UPDATE_EMPLOYER_THEME_LOGO,
  UPDATE_EMPLOYER_THEME_LOGO_ERROR,
  UPDATE_EMPLOYER_THEME_LOGO_LOADING,
  UPDATE_EMPLOYER_THEME_LOGO_SUCCESS,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';

function* getEmployerTheme({ body }) {
  yield put({ type: GET_EMPLOYER_THEME_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.employers.get_employer({ employerId: body.employerId }),
      body,
    });
    yield put({ type: GET_EMPLOYER_THEME_SUCCESS, data: data?.settings?.theme || {} });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_EMPLOYER_THEME_ERROR, errorMessage: '' });
  }
}

function* updateEmployerThemeColorScheme({ body }) {
  yield put({ type: UPDATE_EMPLOYER_THEME_CS_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'PUT',
      url: apiConfig.employers.update_employer_theme_cs({ employerId: body.employerId }),
      body: body.theme,
    });
    yield put({ type: UPDATE_EMPLOYER_THEME_CS_SUCCESS, data: data?.settings?.theme || {} });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_EMPLOYER_THEME_CS_ERROR, errorMessage: '' });
  }
}

function* updateEmployerThemeLogo({ body }) {
  yield put({ type: UPDATE_EMPLOYER_THEME_LOGO_LOADING });
  try {
    const { logoFullVersion, logoIconVersion } = body;
    const formData = new FormData();
    formData.append('logoFullVersion', logoFullVersion);
    formData.append('logoIconVersion', logoIconVersion);

    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.employers.update_employer_theme_logo({ employerId: body.employerId }),
      body: formData,
    });
    yield put({ type: UPDATE_EMPLOYER_THEME_LOGO_SUCCESS, data: data?.settings?.theme || {} });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_EMPLOYER_THEME_LOGO_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployerThemeSaga() {
  yield takeLatest(GET_EMPLOYER_THEME, getEmployerTheme);
  yield takeLatest(UPDATE_EMPLOYER_THEME_CS, updateEmployerThemeColorScheme);
  yield takeLatest(UPDATE_EMPLOYER_THEME_LOGO, updateEmployerThemeLogo);
}
